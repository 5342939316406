import React from "react";
import Banner from "../components/Banner";
import { Helmet } from "react-helmet";
import CommonServiceComponent from "../components/service/CommonServiceComponent";
import Cta from "../components/service/Cta";
import ServiceTab from "../components/ServiceTab";
import Clients from "../components/service/Clients";
import Testimonials from "../components/Testimonials";
import ProjectSection from "../components/ProjectSection";
import Faq from "../components/Faq";
// import KnowMoreSection from "../components/KnowMoreSection";
import { Seo } from "../components/seo";
import Layout from "../components/layout";

const ServiceDetail = () => {
  const banner = {
    title: `<span class="h1-span">ReactJs </span><span class="h1-span"> Development Company</span>`,
    content:
      "We are the top React JS development company, delivering reactive solutions using our experience and technical breakthroughs to build a highly functioning interface.",
  };

  const commonContent6 = {
    title: "Full-Scale Reactjs Development Services",
    para: "We use ReactJs Services to build solid and user-friendly applications by integrating third-party libraries, optimizing interfaces, and thoroughly testing. Our goal is to improve customer experiences and drive business success.",
    img: ["what-service-image-reactjs.webp"],
    imageTitle: 'ReactJs Development Company',
    imageAlt: 'reactjs development agency',
    content: [
      {
        title: "Custom ReactJS Development",
        text: "We use precision, knowledge, and tailored ReactJs Services to bring your unique ideas to life.",
      },
      {
        title: "React Web Development",
        text: "We create high-performance, SEO-optimized websites that will elevate your digital presence.",
      },
      {
        title: "React UI/UX Development",
        text: "We create engaging interfaces using React UI UX development, with a stronger emphasis on user-centric experiences.",
      },
      {
        title: "React Mobile App Development",
        text: "We use our knowledge and cutting-edge technology to develop exciting and user-friendly cross-platform applications.",
      },
      {
        title: "Enterprise App Development",
        text: "We provide enterprise-grade organizations with scalable, secure, and feature-rich applications.",
      },
      {
        title: "Migration and Porting Services",
        text: "We effortlessly convert to React or port existing applications, retaining data and functionality.",
      },
      {
        title: "ReactJS Consulting Services",
        text: "Octet’s Reactjs Developers work with you to make informed decisions, improve your projects, and use best React practices and approaches.",
      },
    ],
  };

  const commonContent = {
    title: "Streamlining Industries with our React Js Development Company",
    para: "Industries need the best React JS development Services to construct or upgrade their websites into highly functioning, versatile, and scalable solutions that meet users' expectations.",
    img: ["who-needs-reactjs.webp"],
    imageTitle: 'ReactJs Development Services',
    imageAlt: 'reactjs development company',
    content: [
      {
        title: "E-commerce",
        text: "Companies who want to increase conversion rates by designing responsive and interactive websites for a better user experience.",
      },
      {
        title: "Fintech",
        text: "Companies wish to provide a safe and secure interface while providing live updates and a smooth, user-friendly experience.",
      },
      {
        title: "Healthcare",
        text: "Companies want to improve operations and provide open communication while prioritizing interface security.",
      },
      {
        title: "Hospitality",
        text: "Companies specializing in creating interactive, dynamic, intuitive interfaces to engage customers on their websites.",
      },
    ],
  };

  const commonContent2 = {
    title: "Work with the Trusted ReactJs Web Development Company",
    para: "As the best Recatjs Development Company, we help companies stay ahead of the digital crowd by developing high-performing and responsive applications.",
    img: ["what-are-benefits-reactjs-services.webp"],
    imageTitle: 'Benefits of ReactJs Services',
    imageAlt: 'what-are-benefits-reactjs-services',
    content: [
      {
        title: "Code Reusability ",
        text: "Our ReactJs Developers save development time by encouraging code reuse and efficiently delivering UI components in projects.",
      },
      {
        title: "Increased Performance",
        text: "As the best React Js Web Development Company, we create a responsive and functional interface by optimizing the speed of your application to improve engagement.",
      },
      {
        title: "Seamless Integration",
        text: "Our ReactJs Services can smoothly connect third-party APIs and frameworks to boost your app's performance.",
      },
      {
        title: "Enhanced User Experience",
        text: "Octst’s ReactJS Agency focuses on user difficulties, develops new solutions, and offers intuitive and beautiful websites.",
      },
    ],
  };
  const commonContent3 = {
    title: "Octet: Top ReactJs Development Company",
    linkText: ``,
    para: `As a top React JS application development company, we have the experience and expertise to produce great ReactJs development projects using a user-centric, transparent, and collaborative approach.`,
    img: ["why-opt-for-reactjs.webp"],
    imageTitle: 'ReactJs Development Company',
    imageAlt: 'reactjs development services',
    content: [
      {
        title: "Expertise and Experience",
        text: "Our ReactJs Agency has years of experience and skill in many industries and is ready to handle your most complicated applications.",
      },
      {
        title: "Collaborative Approach",
        text: "Our ReatJs developers take a collaborative approach, working closely with your in-house team to provide shared insights, feedback, and timely delivery of our services.",
      },
      {
        title: "Timely Delivery",
        text: "Our React.js development company is committed to providing high-quality, functional, and user-friendly solutions with an explicit schedule for future business growth.",
      },
      {
        title: "Continuous Support",
        text: "Octet ensures your business runs well. We prioritize long-term partnerships and answer your concerns even after you have received your services.",
      },
    ],
  };
  const commonContent4 = {
    title: "Approach of Our ReactJs <br /> Development Company",
    para: "Our enthusiastic team of Reactjs Developers systematically provides Reactjs Services for projects, beginning with a knowledge of your business goals and progressing to unique solutions via our tailored approach.",
    img: ["how-we-conduct-react-js-services.webp"],
    imageTitle: 'ReactJs Development Approach',
    imageAlt: 'ReactJs Development Approach',
    content: [
      {
        title: "1. Define UX & Flows",
        text: "We initially identify the UX characteristics and user processes to determine the design structure.",
      },
      {
        title: "3. Build HTML Templates",
        text: "Next, we translate the UI design into HTML templates, which are then transformed into React pages.",
      },
      {
        title: "2. Create UI Designs",
        text: "We create designs while considering brand requirements, typographic design principles, color theory, and UX rules.",
      },
      {
        title: "4. Convert to React Pages",
        text: "Finally, we transform the HTML code to the ReactJs framework for easier understanding and faster deployment.",
      },
    ],
  };
  const commonContent5 = {
    title:
      "Best React js development services will Powe your Products",
    para: "Investing in our ReactJs Development Services provides a comprehensive ecosystem of libraries, tools, and community support, making it a cost-effective alternative for your organization.",
    content: [
      {
        title: "10% Less Development Time",
        text: "The component-based architecture of React.js, the reuse of code components, and quick rendering will all help speed development.",
      },
      {
        title: "30% Increased Productivity",
        text: "The modular and reusable component structure and the robust ecosystem of libraries and tools boost developer productivity.",
      },
      {
        title: "15% Cross-platform efficiency",
        text: "Our React.js developers enable cross-platform development with the same codebase, resulting in cost savings and reduced development time.",
      }, 
      {
        title: "10% Higher Conversion",
        text: "ReactJs' quick rendering, enhanced user experience, and ability to design interactive interfaces contribute to increased conversions.",
      },
    ],
    desc: "*These statistics are from public sources; we do not own them.",
  };
  const cta = {
    title: "Experience Excellence with our Reactjs Development Services?",
  };
  const cta2 = {
    title: "Elevate your business with the Best React Js Development Company",
  };
  const cta3 = {
    title: "Build Future-Ready Products with a React.js Development Company",
  };
  const cta4 = {
    title: "Stand out from the Digital Crowd with our Reactjs Services",
  };
  const cta5 = {
    title: "Start Your ReactJS Project Today!",
  };
  const tabs = [
    {
      link: '/angularjs-development/',
      text: 'Angular Development'
    },
    {
      link: '/branding/',
      text: 'Branding'
    },
    {
      link: '/corporate-training-workshop/',
      text: 'UI UX Workshops and Training'
    },
    {
      link: '/data-visualisation/',
      text: 'Dashboards and Data Visualisation'
    },
    {
      link: '/design-audit/',
      text: 'Design Audit '
    },
    {
      link: '/design-systems/',
      text: 'Design System Engineering'
    },
    {
      link: '/digital-transformation/',
      text: 'Digital Transformation'
    },
    {
      link: '/product-development/',
      text: 'Product Development'
    },
    {
      link: '/rapid-prototyping/',
      text: 'Rapid Prototyping'
    },
    {
      link: '/reactjs-development/',
      text: 'ReactJS Development'
    },
    {
      link: '/ui-development/',
      text: 'UI Development'
    },
    {
      link: '/ui-ux-designing/',
      text: 'UI UX Designing'
    },
    {
      link: '/usability-testing/',
      text: 'Usability Testing'
    },
    {
      link: '/user-acquisition-consulting/',
      text: 'User Acquisition Consultation'
    },
    {
      link: '/user-research/',
      text: 'User Research'
    },
    {
      link: '/ux-consulting/',
      text: 'UX Consultation'
    },
    {
      link: '/vuejs-development/',
      text: 'VueJS Development'
    },
    {
      link: '/wordpress-development/',
      text: 'WordPress Development'
    },
  ];
  const projectData = [
    {
      title: 'Nirvana',
      desc: 'A financial solutions platform',
      Industry: 'FinTech',
      WhatWeDid: [
          {
              title: 'Design Audit',
              link: '/design-audit/'
          },
          {
              title: 'UI UX Designing',
              link: '/ui-ux-designing/'
          },
          {
              title: 'React Development',
              link: '/reactjs-development/'
          }
      ],
      link: '/project/nirvana/',
      images: ["nirvana-project.webp", "nirvana-project-2.webp"],
      firstAlt: "Custom Web Development",
      firstTitle: "Orders List",
      secondAlt: "Web Development Services",
      secondTitle: "Order Status",
    },
    {
      title: 'Alpha Test',
      desc: 'A no-code test automation platform',
      Industry: 'Enterprise',
      WhatWeDid: [
          {
              title: 'User Research',
              link: '/user-research/'
          },
          {
              title: 'UI UX Designing',
              link: '/ui-ux-designing/'
          },
          {
              title: 'Usability Testing',
              link: '/usability-testing/'
          }
      ],
      link: '/project/alpha-test/',
      images: ["alpha-test.webp", "alpha-test-2.webp"],
      firstAlt: "UI UX No-Code Test Automation Dashboard",
      firstTitle: "Automation Dashboard",
      secondAlt: "Test Run Results of Specific Projects",
      secondTitle: "Test Run Results",
    },
  ];

  const Questions = [
    "How are ReactJs Development Services different from Angular and VueJs Development Services?",
    "Do you offer customized ReactJs Development Services?",
    "Can your React js app development company integrate third-party libraries and UI Frameworks?",
    "How does ReactJs Development Services enhance user experience?",
    "What is the duration and cost of your ReactJs Services?",
  ];
  const clientsTitle = "Trusted By Clients Worldwide";
  const faqDes =
    `We understand you might have more inquiries about our React Js Development Company. As a result, for your convenience, we have compiled a list of some of the most often-asked questions by our clients. However, if you still have any questions, please <a title='contact us' target="_blank" rel="noreferrer" href="/contact-us/">contact us</a>.`;
  
    const faqData = [
      {
        para: [
          `ReactJs, <a href="/angularjs-development/" target="_blank" rel="noreferrer">Angular</a> and <a target="_blank" rel="noreferrer" href="/vuejs-development/">VueJs</a> are all part of the Java Script framework but have different features.`,
          `ReactJs solutions build reusable UI Components focusing on unidirectional data flow. In contrast, Angular provides a comprehensive set of features, such as two-way data binding, and follows MVC architecture.`,
          `VueJs is a progressive framework that balances the other two frameworks and offers its features.`
        ],
      },
      {
        para: [
          `We offer customized and the best ReactJs Development Services for your project requirements.`,
          `We have a team of experienced ReactJs Developers who can create a robust, functional, and interactive interface for you to simplify your complex offerings.`,
          `We collaborate closely with you and seek regular feedback to ensure that our services align with your business goals.`
        ],
      },
      {
        para: [
          `We can seamlessly integrate third-party libraries and other UI frameworks in ReactJs projects.`,
          `We have the expertise and experience to incorporate other UI frameworks, such as Bootstrap, to enhance your applications.`,
          `We aim to deliver a functional and interactive application that exceeds your expectations.`
        ],
      },
      {
        para: [
          `With our ReactJs Development Services, we offer visually interactive and responsive interfaces that work on all platforms and browsers.`,
          `Our Reactjs Developers optimize your application's performance through code splitting, lazy loading, and other methods and integrate third-party libraries for better functionality.`,
          `We aim to enhance your user experience and provide a path for sustained business growth.`
        ],
      },
      {
        para: [
          `As the best Reactjs Development Company in India, we understand that every business has unique requirements that require different durations to fulfill.`,
          `Hence, the duration and charges vary based on factors such as business goals, project requirements, and customizations needed.`,
          `We believe in a transparent approach and provide an accurate timeline and quotation for your customized services.`,
          `We aim to deliver a high-quality service with a cost-effective solution to ensure sustained business growth. Hence, we request that you contact us for a detailed project discussion.`
        ],
      },
    ];
  
    return (
      <>
        <Helmet>
          <script type="application/ld+json">
            {`
              {
                "@context": "https://schema.org",
                "@type": "FAQPage",
                "mainEntity": [{
                  "@type": "Question",
                  "name": "How are ReactJs Development Services different from Angular and VueJs Development Services?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "ReactJs, Angular, and VueJs are all part of the Java Script framework but have different features.
              ReactJs solutions build reusable UI Components focusing on unidirectional data flow. In contrast, Angular provides a comprehensive set of features, such as two-way data binding, and follows MVC architecture.
              VueJs is a progressive framework that balances the other two frameworks and offers its features."
                  }
                },{
                  "@type": "Question",
                  "name": "Do you offer customized ReactJs Development Services?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "We offer customized and the best ReactJs Development Services for your project requirements.
              We have a team of experienced ReactJs Developers who can create a robust, functional, and interactive interface for you to simplify your complex offerings.
              We collaborate closely with you and seek regular feedback to ensure that our services align with your business goals."
                  }
                },{
                  "@type": "Question",
                  "name": "Can your React js app development company integrate third-party libraries and UI Frameworks?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "We can seamlessly integrate third-party libraries and other UI frameworks in ReactJs projects.
              We have the expertise and experience to incorporate other UI frameworks, such as Bootstrap, to enhance your applications.
              We aim to deliver a functional and interactive application that exceeds your expectations."
                  }
                },{
                  "@type": "Question",
                  "name": "How does ReactJs Development Services enhance user experience?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "With our ReactJs Development Services, we offer visually interactive and responsive interfaces that work on all platforms and browsers.
              Our Reactjs Developers optimize your application's performance through code splitting, lazy loading, and other methods and integrate third-party libraries for better functionality. 
              We aim to enhance your user experience and provide a path for sustained business growth."
                  }
                },{
                  "@type": "Question",
                  "name": "What is the duration and cost of your ReactJs Services?",
                  "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "As the best Reactjs Development Company in India, we understand that every business has unique requirements that require different durations to fulfill.
              Hence, the duration and charges vary based on factors such as business goals, project requirements, and customizations needed.
              We believe in a transparent approach and provide an accurate timeline and quotation for your customized services.
              We aim to deliver a high-quality service with a cost-effective solution to ensure sustained business growth. Hence, we request that you contact us for a detailed project discussion."
                  }
                }]
              }                         
            `}
          </script>
        </Helmet>
        <Layout>
          <div className="service-detail-page">
            <Banner content={banner} page={"service"} />
            <CommonServiceComponent data={commonContent6} mode={"grey"} />
            <ServiceTab tabs={tabs} />
            <CommonServiceComponent data={commonContent} mode={"light"} />
            <Cta mode="grey" data={cta} />
            <CommonServiceComponent data={commonContent2} mode={"dark"} />
            <CommonServiceComponent data={commonContent3} mode={"light"} />
            <Cta data={cta2} />
            <Clients title={clientsTitle} />
            <ServiceTab tabs={tabs} />
            <CommonServiceComponent data={commonContent4} mode={"light"} />
            <Cta mode="grey" data={cta3} />
            <section className="project-section lg:py-[200px] md:py-[120px] py-[80px] text-white bg-[url('../images/guides.webp')] bg-[length:100%] bg-repeat-y bg-top bg-black-main">
              <div className="container">
                <h2 className="lg:mb-[80px] md:mb-[40px] mb-[30px] font-heading lg:text-[60px] md:text-[50px] text-[38px] leading-[1.36] tracking-[.01em]">
                  Projects we have made <span className="h1-span">with ReactJS</span>
                </h2>
              </div>
              {projectData.map((data, index) => (
                <div key={index}>
                  <ProjectSection num={index} lastLength={projectData} data={data} />
                </div>
              ))}
            </section>
            <Testimonials title={"Read our Client's Take on Us"} />
            <Cta data={cta4} />
            <CommonServiceComponent data={commonContent5} condition mode={"light"} />
            <ServiceTab tabs={tabs} mode={"split"} />
            <Faq Questions={Questions} section des={faqDes} link faqData={faqData} />
            <Cta mode="grey" data={cta5} />
            {/* <KnowMoreSection /> */}
          </div>
        </Layout>
      </>
  );
};

export default ServiceDetail;

export const Head = () => (
  <Seo title="ReactJs Development Company | ReactJs Development Services" description="Experience growth with our ReactJs development company to create dynamic web solutions for a solid online presence with our ReactJs development services." />
)